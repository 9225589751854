<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
    </v-skeleton-loader>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
  },
  watch: {},
  async mounted() {
    this.$router.push({
      name: "CustomerDetails",
      params: { customerId: this.customerId }
    });
  },
  async created() {},
  methods: {},
  computed: {}
};
</script>
